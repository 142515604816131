import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Wrapper from '../Wrapper'
import { DOWNLOADER, PREMIUM } from '../../data/constants'
import { PAYMENT_STATUS } from '../../data/apis'

import { postData } from '../../reducers/app'

const PaymentStatus = ({ invoice }) => {
  const [isLoading, setLoading] = useState(true)
  const dispatch = useDispatch()
  useEffect(() => {
    if (invoice)
      dispatch(postData(PAYMENT_STATUS, { orderId: invoice })).finally(() =>
        setLoading(false)
      )
    if (!invoice) setLoading(false)
  }, [])

  const {
    paymentstatus: { loaded, data, errorDetails },
  } = useSelector(state => state.app)
  return (
    <Wrapper title="Payment Status">
      <div className="col-sm-6">
        <div className="cboxEraForm text-center mt-4">
          {!isLoading && !invoice && (
            <div>
              <i className="fas fa-receipt fa-3x text-danger"></i>
              <p className="pt-4 mb-1 font-weight-bold">Oops..!</p>
              <p className="font-weight-bold">Payment information not found.</p>
            </div>
          )}
          {isLoading && (
            <span>
              <i
                className="fas fa-cog fa-4x fa-spin mb-2 text-primary"
                aria-hidden="true"
              />
              <p className="mt-3">Checking your Payment Status.</p>
              <p className="mt-2">Please wait....</p>
            </span>
          )}
          {loaded && data && data.status === 'success' && !errorDetails && (
            <span>
              <i
                className="far fa-check-circle fa-4x mb-2 text-success"
                aria-hidden="true"
              />
              <p className="mt-4 mb-1 font-weight-bold">
                {data.status && data.status.toUpperCase()} !
              </p>
              <p className="mt-2 mb-1">{data.msg}</p>
              <p className="mt-2">
                Go to{' '}
                <Link to={DOWNLOADER} className="font-weight-bold text-danger">
                  Downloader
                </Link>
              </p>
            </span>
          )}
          {loaded && data && data.status === 'failed' && !errorDetails && (
            <span>
              <i
                className="far fa-times-circle fa-4x mb-1 text-danger"
                aria-hidden="true"
              />
              <p className="mt-4 mb-1 font-weight-bold">
                {data.status && data.status.toUpperCase()} !
              </p>
              <p className="mt-2 mb-1">Sorry, {data.msg}</p>
              <p className="mt-2">
                Go to{' '}
                <Link to={PREMIUM} className="font-weight-bold text-danger">
                  Premium Page
                </Link>{' '}
                and try again.
              </p>
            </span>
          )}
          {errorDetails && loaded && (
            <span>
              <i
                className="fas fa-exclamation-circle fa-4x mb-2 text-danger"
                aria-hidden="true"
              />
              <p className="mt-4 mb-1 font-weight-bold">
                {errorDetails.status}
              </p>
              <p>{errorDetails.msg}</p>
            </span>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

PaymentStatus.defaultProps = {
  invoice: '',
}

PaymentStatus.propTypes = {
  invoice: PropTypes.string,
}

export default PaymentStatus
