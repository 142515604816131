import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../components/SEO'

import PrivateRoute from '../../components/PrivateRoute'
import PaymentStatus from '../../components/Payments/PaymentStatus'
import { getParamFromURL } from '../../helpers'

const PaymentStatusPage = ({ location }) => {
  const invoice = getParamFromURL(location.search, 'invoice')
  return (
    <>
      <SEO page="paymentStatus" />
      <PrivateRoute component={PaymentStatus} invoice={invoice} />
    </>
  )
}

PaymentStatusPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PaymentStatusPage
